<template>
  <div id="service_predictor">
    <div class="container-fluid">
      <div class="row">
        <div
          class="col-md-3 border-end p-0 overflow-y-auto"
          ref="sidebarContainer"
        >
          <div class="col-md-12">
            <div class="p-3 border-bottom">
              <div class="title">Service Availability Predictor</div>
              <div class="form-group">
                <label class="sub-title pb-2">Operator API Key</label>
                <input
                  type="password"
                  name="operatorKey"
                  class="form-control"
                  v-model="operatorKey"
                  :class="operatorKeyError ? 'border-danger' : ''"
                  @change="findOperators"
                  @keyup="isAuthenticationInputValid"
                />
              </div>
              <div class="form-group">
                <label class="sub-title pb-2">Email</label>
                <input
                  type="email"
                  name="email"
                  class="form-control"
                  v-model="email"
                  :class="emailError ? 'border-danger' : ''"
                  @change="findOperators"
                  @keyup="isAuthenticationInputValid"
                />
                <div class="text-danger mb-2" v-if="emailErrorMsg">
                  {{ emailErrorMsg }}
                </div>
              </div>
              <div class="col-md-12 mt-2 mb-1">
                <div class="text-danger mb-2" v-if="keyResponse">
                  {{ keyResponse }}
                </div>
                <button
                  type="button"
                  class="btn btn-predictor"
                  :disabled="disableAuthButton"
                  @click="checkAPIKeyHealth"
                >
                  Authenticate
                </button>
                <!-- <button
                  type="button"
                  class="btn btn-predictor ms-2"
                  @click="clearInputs"
                  v-if="keyResponse"
                >
                  <i class="fa fa-times"></i> Clear
                </button> -->
              </div>

              <div class="form-group">
                <label class="sub-title pb-2">Operator</label>
                <input
                  type="text"
                  name="operator"
                  class="form-control"
                  :value="operator"
                  :disabled="operatorDisabled"
                  :class="operatorError ? 'border-danger' : ''"
                  @change="checkOperatorExist($event.target.value)"
                />
              </div>
              <div
                class="col-md-12 mt-2 mb-1 text-indigo"
                v-if="operatorLoading"
              >
                <i class="fa fa-spinner fa-spin me-1"></i> Fetching available
                operators. Please wait.
              </div>
              <div
                class="col-md-12 mt-2 mb-1 text-danger"
                v-if="
                  operatorError && operatorNames.length > 0 && !operatorLoading
                "
              >
                Operator entered is invalid.
                <a
                  href="#"
                  @click="showOperatorModal = true"
                  class="text-indigo"
                  >Click here</a
                >
                to select an operator.
              </div>
            </div>
          </div>
          <div class="col-md-12 px-3 mt-3">
            <div class="mt-2">
              <base-node
                ref="baseNodeRef"
                :operator-key="operatorKey"
                :operator="operator"
              />
              <bn-by-radius
                ref="baseNodeByRadiusRef"
                :operator-key="operatorKey"
                :operator="operator"
                @get-operators="findOperators"
                @base-node-list="getBaseNodeList"
              />
            </div>
            <div class="mt-2">
              <remote-node
                ref="remoteNodeRef"
                @get-operators="findOperators"
                @clear-rn-marker="handleClearRNMarker"
              />
            </div>
          </div>
          <div class="col-md-12 p-3 mb-3">
            <button
              type="button"
              class="btn btn-predictor me-2 mb-1"
              @click="calculateLost"
            >
              Calculate Path Loss & Speed
            </button>
            <button
              type="button"
              class="btn btn-predictor me-2 mb-1"
              v-if="successful && !showInfoDiv"
              @click="toggleModal"
            >
              Show Modal
            </button>
            <button
              type="button"
              class="btn btn-predictor mb-1"
              v-if="baseNodeList.bn_list?.length"
              @click="toggleBNRadius"
            >
              {{ showBNRadiusDiv ? "Hide BN Radius" : "Show BN Radius" }}
            </button>
          </div>
        </div>
        <div class="col-md-9 p-0">
          <div class="container-fluid">
            <div class="row no-gutters">
              <div class="col-md-12 p-0 position-relative">
                <google-map
                  :operator-key-check="operatorKeyCheck"
                  :base-node-list="baseNodeList"
                  :remote-address="remote_address"
                  :base-node="baseNode"
                  :remote-node="remoteNode"
                  :show-modal="showInfoDiv"
                  :clear-remote-node="clearRNMarker"
                  :load-base-nodes="loadingBaseNodes"
                  @reset-all="clearInputs"
                  @update-lat-lng="handleUpdateLatLng"
                  @update-bn-radius="handleUpdateBaseNodeRadius"
                  @update-base-node="handleUpdateBaseNode"
                />
                <!-- Overlay Modal -->
                <div class="overlay" v-if="successful && showInfoDiv">
                  <div class="overlay-content">
                    <div class="card">
                      <div class="card-body set-max-height">
                        <div class="close" @click="toggleModal">&times;</div>
                        <div class="row">
                          <div class="col-md-12" v-if="apiResponse">
                            <div class="text-danger text-center">
                              API Response: {{ apiResponse }}<br />Please try
                              again using another address.
                            </div>
                          </div>
                          <div
                            class="modal-flex-container col-md-12"
                            v-if="profileSvg"
                          >
                            <div class="info-column">
                              <div class="info-text" v-if="linkCloseConfidence">
                                Link Close Confidence:
                                {{ linkCloseConfidence }}
                              </div>
                              <div class="info-text" v-if="pathLossData">
                                Predicted Path Loss:
                                {{ pathLossData.average }}
                              </div>
                            </div>
                            <div class="graph-column img-enlarge">
                              <div class="diagram-title">Link Diagram</div>
                              <img
                                :src="profileSvg"
                                class="svg-container"
                                id="profile-svg-container"
                                @click="enlargeImage('line')"
                              />
                              <button
                                class="enlarge-button"
                                @click="enlargeImage('line')"
                              >
                                <i class="fa fa-expand"></i> Enlarge Image
                              </button>
                            </div>
                          </div>
                          <!-- <div class="col-md-12 graph-bg">
                            <div class="row">
                              <div
                                class="col-md-6 img-enlarge"
                                v-if="pathLossData"
                              >
                                <img
                                  v-if="pathLossSvg"
                                  :src="pathLossSvg"
                                  class="svg-container"
                                  id="path-loss-svg-container"
                                />
                                <button
                                  class="enlarge-button"
                                  @click="enlargeImage('path-loss')"
                                >
                                  <i class="fa fa-expand"></i> Enlarge Image
                                </button>
                              </div>
                              <div
                                class="col-md-6 img-enlarge"
                                v-if="linkSpeedData"
                              >
                                <img
                                  v-if="linkSpeedSvg"
                                  :src="linkSpeedSvg"
                                  class="svg-container"
                                  id="link-speed-svg-container"
                                />
                                <button
                                  class="enlarge-button"
                                  @click="enlargeImage('link-speed')"
                                >
                                  <i class="fa fa-expand"></i> Enlarge Image
                                </button>
                              </div>
                            </div>
                          </div> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="dialog"
        id="svg-popup"
        v-if="dialogVisible"
        @click.self="closeDialog"
      >
        <div
          class="dialog-content"
          :class="svgType === 'line' ? 'diagram-width' : 'graph-width'"
          @click.stop
        >
          <button @click="closeDialog" class="close-icon">
            <i class="fa fa-times" aria-hidden="true"></i>
          </button>
          <div class="row">
            <div class="col-md-12">
              <img
                :src="svgContent"
                class="dialog-svg-container"
                id="svg-container"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal -->
  <div
    class="modal fade show"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modalLabel"
    aria-hidden="true"
    :class="showOperatorModal ? 'd-block' : ''"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalLabel">Operator List</h5>
          <button
            type="button"
            class="btn-close"
            @click="showOperatorModal = false"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div v-if="operatorNames.length > 0">
            <p>Select an operator from the list below:</p>
            <ul class="list-group">
              <li
                v-for="(operator, index) in operatorNames"
                :key="index"
                class="list-group-item fw-bold"
              >
                {{ operator }}
                <div class="float-end">
                  <button
                    type="button"
                    class="btn btn-primary"
                    @click="selectOperator(operator)"
                  >
                    Select Operator
                  </button>
                </div>
              </li>
            </ul>
          </div>
          <div v-else>
            <p>No operators found at this address.</p>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            @click="showOperatorModal = false"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseNode from "./BaseNode.vue";
import RemoteNode from "./RemoteNode.vue";
import BNByRadius from "./BNByRadius.vue";
import GoogleMap from "./GoogleMap.vue";
export default {
  components: {
    "base-node": BaseNode,
    "remote-node": RemoteNode,
    "bn-by-radius": BNByRadius,
    "google-map": GoogleMap,
  },
  data() {
    return {
      successful: false,
      operatorKey: "",
      email: "",
      operator: "",
      keyResponse: "",
      remote_address: "",
      showOperatorModal: false,
      disableAuthButton: true,
      emailError: false,
      emailErrorMsg: "",
      operatorError: false,
      operatorLoading: false,
      operatorKeyCheck: false,
      operatorKeyError: false,
      operatorDisabled: true,
      clearRNMarker: false,
      activeTab: "base-remote-node",
      baseNode: { lat: "", lng: "" },
      remoteNode: { lat: "", lng: "" },
      svgContent: "",
      profileSvg: "",
      pathLossData: "",
      linkSpeedData: "",
      pathLossSvg: "",
      linkSpeedSvg: "",
      linkCloseConfidence: 0,
      showInfoDiv: false,
      showBNRadiusDiv: false,
      dialogVisible: false,
      loadingBaseNodes: false,
      abortController: null,
      svgType: "",
      apiResponse: "",
      operatorNames: [],
      baseNodeList: [],
    };
  },
  mounted() {
    const rowElement = this.$refs.sidebarContainer;
    if (rowElement && window.innerWidth > 767) {
      rowElement.style.height = window.innerHeight - 40 + "px";
    }
    this.toogleActiveTab(this.activeTab);
    window.addEventListener("resize", this.toggleModal);
  },
  watch: {
    profileSvg() {
      this.makeSVGResponsive("profile-svg-container");
    },
    pathLossSvg() {
      this.makeSVGResponsive("path-loss-svg-container");
    },
    linkSpeedSvg() {
      this.makeSVGResponsive("link-speed-svg-container");
    },
  },
  methods: {
    toogleActiveTab(tab) {
      this.activeTab = tab;
      let remoteRef =
        this.activeTab == "base-remote-node"
          ? this.$refs.remoteNodeRef
          : this.$refs.remoteNodeByRadiusRef;
      remoteRef.findByAddress = true;
      remoteRef.remoteAddress = true;
    },
    clearInputs(data) {
      this.emailErrorMsg = "";
      this.keyResponse = "";
      this.remote_address = "";
      this.svgContent = "";
      this.profileSvg = "";
      this.pathLossData = "";
      this.linkSpeedData = "";
      this.pathLossSvg = "";
      this.linkSpeedSvg = "";
      this.svgType = "";
      this.apiResponse = "";
      this.linkCloseConfidence = 0;
      this.operatorNames = [];
      this.baseNodeList = [];

      this.baseNode.lat = "";
      this.baseNode.lng = "";
      this.remoteNode.lat = "";
      this.remoteNode.lng = "";

      this.successful = false;
      this.showOperatorModal = false;
      this.showInfoDiv = false;
      this.showBNRadiusDiv = false;
      this.dialogVisible = false;
      this.clearRNMarker = false;

      if (data.remoteOnly) {
        this.$refs.baseNodeRef.clearInputs();
        this.$refs.baseNodeByRadiusRef.clearInputs();
        let remoteRef =
          this.activeTab == "base-remote-node"
            ? this.$refs.remoteNodeRef
            : this.$refs.remoteNodeByRadiusRef;
        remoteRef.clearInputs();
      }
    },
    enlargeImage(diagram) {
      this.dialogVisible = true;
      if (diagram == "line") {
        this.svgContent = this.profileSvg;
        this.svgType = "line";
      }
      if (diagram == "path-loss") {
        this.svgContent = this.pathLossSvg;
        this.svgType = "graph";
      }
      if (diagram == "link-speed") {
        this.svgContent = this.linkSpeedSvg;
        this.svgType = "graph";
      }
      // Call makeSVGResponsive after SVG content is updated
      this.$nextTick(() => {
        this.makeDialogSVGResponsive();
      });
    },
    closeDialog() {
      this.dialogVisible = false;
    },
    toggleModal() {
      this.showInfoDiv = !this.showInfoDiv;
      if (this.showInfoDiv) {
        // Call makeSVGResponsive after SVG content is updated
        this.$nextTick(() => {
          this.makeSVGResponsive("profile-svg-container");
          this.makeSVGResponsive("path-loss-svg-container");
          //this.makeSVGResponsive("link-speed-svg-container");
        });
      }
    },
    makeSVGResponsive(svg_id) {
      const svgContainer = document.getElementById(svg_id);
      if (svgContainer) {
        const svg = svgContainer.querySelector("svg");
        if (svg) {
          svg.removeAttribute("width");
          svg.removeAttribute("height");

          // Set viewBox attribute based on the SVG content's bounding box
          const bbox = svg.getBBox();
          svg.setAttribute(
            "viewBox",
            `${bbox.x} ${bbox.y} ${bbox.width} ${bbox.height}`
          );

          let diagram = "";
          if (svg_id == "path-loss-svg-container") {
            diagram = "path-loss";
          } else if (svg_id == "link-speed-svg-container") {
            diagram = "link-speed";
          } else {
            diagram = "line";
          }

          // Add click event listener to the svg element
          svg.addEventListener("click", () => {
            this.enlargeImage(diagram);
          });
        }
      }
      const rowElement = this.$refs.sidebarContainer;
      if (rowElement && window.innerWidth > 767) {
        rowElement.style.height = window.innerHeight - 40 + "px";
      }
    },
    makeDialogSVGResponsive() {
      const svgId = "svg-container";
      const svgContainer = document
        .getElementById("svg-popup")
        .querySelector(`#${svgId}`);
      if (svgContainer) {
        const svg = svgContainer.querySelector("svg");
        if (svg) {
          svg.removeAttribute("width");
          svg.removeAttribute("height");

          // Set viewBox attribute based on the SVG content's bounding box
          const bbox = svg.getBBox();
          svg.setAttribute(
            "viewBox",
            `${bbox.x} ${bbox.y} ${bbox.width} ${bbox.height}`
          );
        }
      }
    },
    selectOperator(val) {
      this.operator = val;
      this.operatorError = false;
      this.showOperatorModal = false;
    },
    checkAPIKeyHealth() {
      this.emailError = false;
      this.operatorKeyError = false;
      this.operatorDisabled = true;
      this.disableAuthButton = false;
      this.operatorError = false;
      this.operator = "";
      this.keyResponse = "";
      if (this.operatorKey != "" && this.isEmailValid()) {
        let loader = this.$loading.show({
          canCancel: true,
          color: "#0367a9",
        });

        const postUrl =
          "https://tcc-network-planning.uw.r.appspot.com/v0/health/api-key";

        // Define the request data
        const requestData = {
          post_url: postUrl,
          api_key: this.operatorKey,
        };

        try {
          this.$axios
            .post("services", requestData)
            .then((response) => {
              loader.hide();
              this.operatorKeyCheck = true;
              if (response.data.detail) {
                this.keyResponse = response.data.detail;
                this.operatorKeyError = true;
              } else {
                if (!response.data.operator) {
                  this.keyResponse = "Invalid API Key";
                } else {
                  if (response.data.operator != "Tarana") {
                    this.operator = response.data.operator;
                  } else {
                    this.operatorDisabled = false;
                  }
                  alert("Successfully Authenticated");
                }
              }
            })
            .catch(() => {
              loader.hide();
              console.log("Error");
            });
        } catch (error) {
          loader.hide();
          console.error("Error:", error);
        }
      } else {
        this.operatorKeyError = this.operatorKey != "" ? false : true;
        this.emailError = this.isEmailValid() ? false : true;
        this.disableAuthButton =
          !this.operatorKeyError && !this.emailError ? false : true;
      }
    },
    handleUpdateLatLng(data) {
      let remoteRef =
        this.activeTab == "base-remote-node"
          ? this.$refs.remoteNodeRef
          : this.$refs.remoteNodeByRadiusRef;
      remoteRef.updateFields(data);
      if (!data.clicked && data.address) {
        this.findOperators();
      }
    },
    handleUpdateBaseNodeRadius(data) {
      this.loadingBaseNodes = true;
      let baseRef = this.$refs.baseNodeByRadiusRef;
      baseRef.updateFields(data);
      this.operatorKeyCheck = false;
      this.operatorError = this.operator != "" ? false : true;
      let remoteRef =
        this.activeTab == "base-remote-node"
          ? this.$refs.remoteNodeRef
          : this.$refs.remoteNodeByRadiusRef;
      remoteRef.addressUpdated = true;
      this.remote_address = remoteRef.rn_address;
    },
    handleUpdateBaseNode(data) {
      let baseRef = this.$refs.baseNodeRef;
      baseRef.updateFields(data);
    },
    isAuthenticationInputValid() {
      this.disableAuthButton =
        this.operatorKey != "" && this.isEmailValid() ? false : true;
    },
    isEmailValid() {
      this.emailErrorMsg = "";
      if (this.email != "") {
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        const checkEmail = emailPattern.test(this.email);
        if (!checkEmail) {
          this.emailErrorMsg = "Invalid Email";
        }
        return checkEmail;
      }
      return false;
    },
    handleClearRNMarker() {
      this.clearRNMarker = true;
    },
    findOperators() {
      let remoteRef =
        this.activeTab == "base-remote-node"
          ? this.$refs.remoteNodeRef
          : this.$refs.remoteNodeByRadiusRef;
      remoteRef.addressError = false;
      if (remoteRef.addressUpdated) {
        this.remote_address = remoteRef.rn_address;
      }
      this.loadingBaseNodes = false;
      if (this.remote_address && this.operatorKey && !this.operatorDisabled) {
        this.operatorLoading = true;
        const postUrl =
          "https://tcc-network-planning.uw.r.appspot.com/v0/find/operators";

        // Define the request data
        const requestData = {
          post_url: postUrl + "/" + encodeURIComponent(this.remote_address),
          api_key: this.operatorKey,
        };

        // Abort previous request if still running
        if (this.abortController) {
          this.abortController.abort();
        }

        // Create a new AbortController
        this.abortController = new AbortController();
        try {
          this.$axios
            .post("services", requestData, {
              signal: this.abortController.signal,
            })
            .then((response) => {
              this.operatorLoading = false;
              if (response.data.detail) {
                this.addressErrorMsg = response.data.detail;
              } else if (response.data.operator_list) {
                let operatorList = response.data.operator_list;
                // Extract names from operatorList
                this.operatorNames = operatorList.map(
                  (operator) => operator.name
                );
                this.checkOperatorExist();
              }
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        } catch (error) {
          console.error("Error:", error);
        }
      } else {
        remoteRef.addressError = !this.remote_address ? true : false;
      }
    },
    getBaseNodeList(data = "") {
      this.loadingBaseNodes = false;
      this.showBNRadiusDiv = true;
      if (data) {
        this.baseNodeList = data;
        this.baseNodeList.showBNRadius =
          this.baseNodeList.bn_list.length > 0 ? true : false;
      }
    },
    toggleBNRadius() {
      this.showBNRadiusDiv = !this.showBNRadiusDiv;
      this.baseNodeList.showBNRadius = this.showBNRadiusDiv;
    },
    isFormValid() {
      let remoteRef =
        this.activeTab == "base-remote-node"
          ? this.$refs.remoteNodeRef
          : this.$refs.remoteNodeByRadiusRef;

      // Perform validation on BaseNode and RemoteNode
      const baseNodeValid = this.$refs.baseNodeRef.isFormValid();
      const remoteNodeValid = remoteRef.isFormValid();
      this.checkOperatorExist();
      return (
        baseNodeValid &&
        remoteNodeValid &&
        this.operatorKey.trim() &&
        this.email.trim() &&
        this.operator.trim() &&
        !this.operatorError &&
        !this.operatorLoading
      );
    },
    checkOperatorExist(value = "") {
      if (value) this.operator = value;
      this.operatorError = false;
      if (this.operatorNames.length > 0) {
        if (!this.operatorNames.includes(this.operator)) {
          this.operatorError = true;
        }
      }
    },
    calculateLost() {
      let remoteRef =
        this.activeTab == "base-remote-node"
          ? this.$refs.remoteNodeRef
          : this.$refs.remoteNodeByRadiusRef;

      let baseRef = this.$refs.baseNodeRef;

      if (this.isFormValid()) {
        this.successful = false;
        this.showInfoDiv = false;
        this.apiResponse = "";
        let jsonArray = [];

        let bnObject = this.$refs.baseNodeRef.getJsonData();
        if (!this.$refs.baseNodeRef.disabled) {
          jsonArray["bn"] = bnObject;
        }

        let rnObject = remoteRef.getJsonData();
        jsonArray["rn_list"] = [rnObject];

        let loader = this.$loading.show({
          canCancel: true,
          color: "#0367a9",
        });

        const postUrl =
          "https://tcc-network-planning.uw.r.appspot.com/v0/predict/sector";

        // Define the request data
        const jsonObject = { ...jsonArray };
        if (this.operator != "") {
          jsonObject.operator = this.operator;
        }
        if (this.email != "") {
          jsonObject.email = this.email;
        }
        const requestData = {
          post_url: postUrl,
          json_data: jsonObject,
          api_key: this.operatorKey,
        };

        try {
          this.$axios
            .post("services", requestData)
            .then((response) => {
              this.successful = true;
              this.showInfoDiv = true;
              loader.hide();

              if (response.data.detail) {
                this.apiResponse = response.data.detail;
              } else {
                if (response.data.bn) {
                  this.operator = response.data.operator;
                  this.baseNode.lat = response.data.bn.latitude
                    ? parseFloat(response.data.bn.latitude)
                    : "";
                  this.baseNode.lng = response.data.bn.longitude
                    ? parseFloat(response.data.bn.longitude)
                    : "";
                  baseRef.updateFields(response.data.bn);
                }

                if (response.data.rn_list.length > 0) {
                  if (response.data.rn_list[0].rn) {
                    this.remoteNode.lat = parseFloat(
                      response.data.rn_list[0].rn.latitude
                    );
                    this.remoteNode.lng = parseFloat(
                      response.data.rn_list[0].rn.longitude
                    );
                    this.remote_address = response.data.rn_list[0].rn.address;
                  }
                  if (response.data.rn_list[0].aiml_output.path_loss) {
                    this.pathLossData =
                      response.data.rn_list[0].aiml_output.path_loss;
                    this.pathLossSvg = this.pathLossData.cdf_url;
                    // Call makeSVGResponsive after SVG content is updated
                    this.$nextTick(() => {
                      this.makeSVGResponsive("path-loss-svg-container");
                    });
                  }
                  if (response.data.rn_list[0].aiml_output.link_speed) {
                    this.linkSpeedData =
                      response.data.rn_list[0].aiml_output.link_speed;
                    this.linkSpeedSvg = this.linkSpeedData.cdf_url;
                    // Call makeSVGResponsive after SVG content is updated
                    this.$nextTick(() => {
                      this.makeSVGResponsive("link-speed-svg-container");
                    });
                  }
                  if (
                    response.data.rn_list[0].aiml_output.link_close_confidence
                  ) {
                    this.linkCloseConfidence =
                      response.data.rn_list[0].aiml_output.link_close_confidence;
                  }
                  if (response.data.rn_list[0].profile_url) {
                    this.profileSvg = response.data.rn_list[0].profile_url;
                    // Call makeSVGResponsive after SVG content is updated
                    this.$nextTick(() => {
                      this.makeSVGResponsive("profile-svg-container");
                    });
                  }
                } else {
                  this.successful = false;
                  this.remoteNode.lat = "";
                  this.remoteNode.lng = "";
                  remoteRef.rn_above_open = "";
                  remoteRef.rn_above_roof = "";
                  remoteRef.rn_location = "";
                }
              }
            })
            .catch(() => {
              loader.hide();
              console.log("Error");
            });
        } catch (error) {
          loader.hide();
          console.error("Error:", error);
        }
      } else {
        this.operatorKeyError = this.operatorKey != "" ? false : true;
        this.checkOperatorExist();
      }
    },
  },
};
</script>
