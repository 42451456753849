<template>
  <div class="map-container" ref="mapDivRef"></div>
  <div v-if="loadingBaseNodes" class="map-loading">
    <i class="fa fa-spinner fa-spin me-1"></i> Loading
  </div>
  <!-- Map and Hybrid Toggle Buttons -->
  <!-- <div class="map-toggle-buttons">
    <button
      class="btn toggle-btn"
      @click="setMapType('roadmap')"
      :class="{ 'active-option': mapType === 'roadmap' }"
      id="roadmap"
    >
      Map
    </button>
    <button
      class="btn toggle-btn"
      @click="setMapType('hybrid')"
      :class="{ 'active-option': mapType === 'hybrid' }"
      id="hybrid"
    >
      Hybrid
    </button>
  </div> -->
  <button class="btn lock-zoom-btn" @click="resetAllValues" v-if="resetAll">
    Reset
  </button>
  <!-- <button class="btn lock-zoom-btn" @click="toggleLockZoom" v-else>
    {{ lockZoom ? "Unlock Zoom" : "Lock Zoom" }}
  </button> -->
</template>

<script>
import { ref, onMounted, watch } from "vue";
import { MarkerClusterer } from "@googlemaps/markerclusterer";

export default {
  name: "GoogleMap",
  props: {
    operatorKeyCheck: Boolean,
    baseNodeList: Object,
    remoteAddress: String,
    baseNode: Object,
    remoteNode: Object,
    showModal: Boolean,
    clearRemoteNode: Boolean,
    loadBaseNodes: Boolean,
  },
  emits: [
    "update-lat-lng",
    "update-bn-radius",
    "reset-all",
    "update-base-node",
  ],
  setup(props, { emit }) {
    const mapDivRef = ref(null);
    const mapType = ref("hybrid");
    let map = null;
    let route = null;
    let loadingBaseNodes = ref(false);
    let clusterClicked = ref(false);
    let lockZoom = ref(false);
    let resetAll = ref(false);
    let mapClickEvent = ref(false);
    let mapDragEvent = ref(false);
    let addressChanged = ref(false);
    let zoomChanged = ref(false);
    let clearAllMarkers = ref(true);
    let newLocation = null;
    let bnMarkerIcon = null;
    let rnMarkerIcon = null;
    let baseMarker = null;
    let remoteMarker = null;
    let infoWindow = null;
    let bnEncodedSVG = null;
    let rnEncodedSVG = null;
    let mapClickListener = null;
    let baseNodeMarkers = [];
    let radiusCircle = null;
    let radiusRectangle = null;
    let markerClusterer = null;
    let polygonArray = [];
    let polylineArray = [];

    const toggleLockZoom = () => {
      lockZoom.value = !lockZoom.value;
      if (map) {
        if (lockZoom.value) {
          google.maps.event.clearListeners(map, "idle");
          google.maps.event.clearListeners(map, "zoom_changed");
          google.maps.event.clearListeners(map, "dragstart");
          google.maps.event.clearListeners(map, "dragend");
        } else {
          if (map.getZoom() > 12 && clusterClicked.value) {
            map.setZoom(12);
            zoomChanged.value = true;
            clusterClicked.value = false;
          }
          getMapCenterAndRadius();
          handleZoomDragforBN();
        }
      }
    };

    const setMapType = (type) => {
      if (map) {
        map.setMapTypeId(type);
        mapType.value = type; // Update active map type
      }
    };

    const resetAllValues = () => {
      // Clear previous MarkerClusterer and markers
      if (markerClusterer) {
        markerClusterer.clearMarkers();
      }

      if (radiusCircle) {
        radiusCircle.setMap(null);
      }

      if (radiusRectangle) {
        radiusRectangle.setMap(null);
      }

      // Clear existing polyline and markers
      if (route) {
        route.setMap(null); // Remove polyline
        route = null; // Reset route variable
      }
      if (baseMarker) {
        baseMarker.setMap(null); // Remove base marker
        baseMarker = null; // Reset baseMarker variable
      }

      if (remoteMarker) {
        remoteMarker.setMap(null); // Remove remote marker
        remoteMarker = null; // Reset remoteMarker variable
      }
      newLocation = null;
      lockZoom.value = false;
      resetAll.value = false;
      loadingBaseNodes.value = false;
      addressChanged.value = false;
      mapClickEvent.value = false;
      mapDragEvent.value = false;
      clusterClicked.value = false;

      if (clearAllMarkers.value) {
        map.setZoom(12);
      }

      google.maps.event.clearListeners(map, "idle");
      google.maps.event.clearListeners(map, "zoom_changed");
      google.maps.event.clearListeners(map, "click");
      google.maps.event.clearListeners(map, "dragstart");
      google.maps.event.clearListeners(map, "dragend");

      getMapCenterAndRadius();
      handleZoomDragforBN();

      emit("reset-all", { remoteOnly: clearAllMarkers.value });

      // Re-add the map click listener
      mapClickListener = map.addListener("click", (event) => {
        mapClickHandler(event.latLng);
      });
    };

    // Define the callback function to initialize the map
    const initMap = () => {
      const options = {
        styles: [
          {
            elementType: "labels",
            stylers: [{ visibility: "on" }],
          },
          {
            featureType: "administrative",
            elementType: "geometry",
            stylers: [{ visibility: "on" }],
          },
          {
            featureType: "road",
            elementType: "geometry",
            stylers: [{ visibility: "on" }],
          },
          {
            featureType: "landscape",
            elementType: "geometry",
            stylers: [{ visibility: "on" }],
          },
        ],
      };

      const mapOptions = {
        center: { lat: 33.567395, lng: -97.02491 },
        zoom: 12,
        tilt: 0, // Ensure the map starts with no tilt
        disableDefaultUI: true,
        mapTypeId: "hybrid",
      };

      if (mapDivRef.value) {
        //const mapWidth = mapDivRef.value.offsetWidth;
        const mapHeight = window.innerHeight - 40;
        mapDivRef.value.style.height = mapHeight + "px";
      }

      map = new google.maps.Map(mapDivRef.value, mapOptions);
      infoWindow = new google.maps.InfoWindow();

      if (!lockZoom.value) {
        clusterClicked.value = false;
        handleZoomDragforBN();
      }

      bnMarkerIcon = {
        path: google.maps.SymbolPath.CIRCLE,
        scale: 8,
        fillColor: "#025287",
        fillOpacity: 1,
        strokeWeight: 10,
        strokeColor: "#025287",
        strokeOpacity: 0.4,
      };

      rnMarkerIcon = {
        path: google.maps.SymbolPath.CIRCLE,
        scale: 8,
        fillColor: "#c14d23",
        fillOpacity: 1,
        strokeWeight: 10,
        strokeColor: "#c14d23",
        strokeOpacity: 0.4,
      };

      // Attach the click event listener to the map
      mapClickListener = map.addListener("click", (event) => {
        mapClickHandler(event.latLng);
      });

      const bnSVGIcon = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" width="40" height="40">
        <path d="M18,0 C25.732,0 32,6.641 32,14.6 C32,27.963 18,40 18,40 C18,40 4,28.064 4,14.6 C4,6.641 10.268,0 18,0 Z" id="Shape" fill="#025287" />
        <circle cx="18" cy="18" r="7" fill="#000"/>
      </svg>`;

      // Encode the SVG to a Data URL
      bnEncodedSVG =
        "data:image/svg+xml;charset=UTF-8," + encodeURIComponent(bnSVGIcon);

      const rnSVGIcon = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" width="40" height="40">
        <path d="M18,0 C25.732,0 32,6.641 32,14.6 C32,27.963 18,40 18,40 C18,40 4,28.064 4,14.6 C4,6.641 10.268,0 18,0 Z" id="Shape" fill="#c14d23" />
        <circle cx="18" cy="18" r="7" fill="#000"/>
      </svg>`;

      // Encode the SVG to a Data URL
      rnEncodedSVG =
        "data:image/svg+xml;charset=UTF-8," + encodeURIComponent(rnSVGIcon);
    };

    onMounted(() => {
      const key = process.env.VUE_APP_GOOGLEMAPS_KEY;

      // Set the initMap function to the window object to ensure it's accessible globally
      window.initMap = initMap;

      // Load the necessary libraries asynchronously
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${key}&libraries=geometry,places&callback=initMap`;
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);

      // Event listener for window resize
      window.addEventListener("resize", resizeMap);
    });

    // Watch for changes in props
    watch(
      [
        () => props.operatorKeyCheck,
        () => props.baseNodeList,
        () => props.remoteAddress,
        () => props.baseNode,
        () => props.remoteNode,
        () => props.showModal,
        () => props.clearRemoteNode,
        () => props.loadBaseNodes,
      ],
      (
        [
          operatorKeyCheck,
          baseNodeList,
          remoteAddress,
          baseNode,
          remoteNode,
          showModal,
          clearRemoteNode,
          loadBaseNodes,
        ],
        [
          prevOperatorKeyCheck,
          prevBaseNodeList,
          prevRemoteAddress,
          prevBaseNode,
          prevRemoteNode,
          prevShowModal,
          prevClearRemoteNode,
          prevLoadBaseNodes,
        ]
      ) => {
        if (map) {
          loadingBaseNodes.value = loadBaseNodes;
          if (operatorKeyCheck) {
            getMapCenterAndRadius();
          }
          if (baseNodeList.bn_list || showModal) {
            updateMapWithBaseNodes(baseNodeList);
          }
          if (remoteAddress) {
            if (remoteAddress !== prevRemoteAddress) {
              addressChanged.value = true;
              clusterClicked.value = false;
              zoomChanged.value = false;
            }
            geocodeAddress(remoteAddress);
          }

          if (clearRemoteNode) {
            clearAllMarkers.value = false;
            resetAllValues();
          }
          updateMap(baseNode, remoteNode);
        }
      },
      { deep: true }
    );

    const handleZoomDragforBN = () => {
      let zooming = false; // Flag to track zooming activity
      let dragging = false; // Flag to track dragging activity
      // Detect when zooming starts
      map.addListener("zoom_changed", () => {
        zooming = true;
      });

      // Detect when dragging starts
      map.addListener("dragstart", () => {
        dragging = true;
      });

      // Detect when dragging ends
      map.addListener("dragend", () => {
        dragging = true; // Mark dragging as started
      });

      // Detect when zooming stops (idle event fires after movement completes)
      map.addListener("idle", () => {
        if (zooming) {
          zooming = false;
          setTimeout(() => {
            getMapCenterAndRadius();
          }, 1500); // Delay execution by 1.5 seconds after zoom stops
        }

        if (dragging) {
          dragging = false;
          setTimeout(() => {
            getMapCenterAndRadius();
          }, 1500); // Delay execution by 1.5 second after dragging stops
        }
      });
    };

    // Function to handle map clicks and plot RN marker
    const mapClickHandler = (location) => {
      let rnSvgMarkerIcon = {
        url: rnEncodedSVG, // Use the encoded SVG Data URL
        scaledSize: new google.maps.Size(40, 40), // Set the size of the icon
        anchor: new google.maps.Point(20, 40),
      };

      // Remove existing RN marker, if any
      if (remoteMarker) {
        remoteMarker.setMap(null);
      }

      // Create a new marker at the clicked location
      remoteMarker = new google.maps.Marker({
        position: location,
        icon: rnSvgMarkerIcon,
        map: map,
        title: "RN Marker",
      });

      const clickedLatLng = {
        lat: location.lat(),
        lng: location.lng(),
      };

      mapClickEvent.value = true;
      newLocation = clickedLatLng;
      updateLocationInfo(clickedLatLng);
    };

    const getMapCenterAndRadius = () => {
      if (!map) return;

      // Get the bounds of the visible map area
      const bounds = map.getBounds();
      const center = map.getCenter();

      if (bounds && center) {
        // Center coordinates
        const centerLat = center.lat();
        const centerLng = center.lng();

        zoomChanged.value = true;

        const radius = calculateRadiusFromBounds();

        console.log("Center Latitude:", centerLat);
        console.log("Center Longitude:", centerLng);
        console.log("Radius (meters):", radius);

        if (!clusterClicked.value && !lockZoom.value) {
          // Emit the center and radius info
          emit("update-bn-radius", {
            latitude: centerLat,
            longitude: centerLng,
            radius,
          });
        }
      }
    };

    const calculateRadiusFromBounds = () => {
      // Calculate the rectangle bounds
      const bounds = map.getBounds();

      const boundsRect = {
        north: bounds.getNorthEast().lat(),
        east: bounds.getNorthEast().lng(),
        south: bounds.getSouthWest().lat(),
        west: bounds.getSouthWest().lng(),
      };
      const toRadians = (degrees) => (degrees * Math.PI) / 180;

      const earthRadius = 6371000; // Earth's radius in meters

      // Extract bounds coordinates
      const { north, east, south, west } = boundsRect;

      // Calculate the center of the bounds
      const centerLat = (north + south) / 2;
      const centerLng = (east + west) / 2;

      // Function to calculate distance using Haversine formula
      const haversineDistance = (lat1, lng1, lat2, lng2) => {
        const dLat = toRadians(lat2 - lat1);
        const dLng = toRadians(lng2 - lng1);

        const a =
          Math.sin(dLat / 2) * Math.sin(dLat / 2) +
          Math.cos(toRadians(lat1)) *
            Math.cos(toRadians(lat2)) *
            Math.sin(dLng / 2) *
            Math.sin(dLng / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        return earthRadius * c;
      };

      // Calculate distances to each corner of the bounds
      const distances = [
        haversineDistance(centerLat, centerLng, north, east), // NE corner
        haversineDistance(centerLat, centerLng, north, west), // NW corner
        haversineDistance(centerLat, centerLng, south, east), // SE corner
        haversineDistance(centerLat, centerLng, south, west), // SW corner
      ];

      // Get the maximum distance as the effective radius
      const radius = Math.max(...distances);

      console.log(
        `Radius from center to farthest corner: ${radius.toFixed(2)} meters`
      );
      return radius;
    };

    const geocodeAddress = (address) => {
      if (addressChanged.value && !mapClickEvent.value && !mapDragEvent.value) {
        newLocation = null;
      }
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ address: address }, (results, status) => {
        if (status === "OK") {
          const location = results[0].geometry.location;
          let latLng = {
            lat: location.lat(),
            lng: location.lng(),
          };
          if (newLocation) {
            latLng = newLocation;
          } else {
            emit("update-lat-lng", {
              coordinates: latLng,
            });
          }
          placeAndDragMarker(latLng);
        } else {
          console.error(
            "Geocode was not successful for the following reason:",
            status
          );
        }
      });
    };

    const getAddressFromLatLng = (latLng, callback) => {
      const geocoder = new google.maps.Geocoder();

      geocoder.geocode({ location: latLng }, (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            const address = results[0].formatted_address;
            callback(address);
          } else {
            console.log("No results found");
            callback(null);
          }
        } else {
          console.error("Geocoder failed due to:", status);
        }
      });
    };

    const placeAndDragMarker = (location) => {
      let rnSvgMarkerIcon = {
        url: rnEncodedSVG, // Use the encoded SVG Data URL
        scaledSize: new google.maps.Size(40, 40), // Set the size of the icon
        anchor: new google.maps.Point(20, 40),
      };

      if (!remoteMarker) {
        remoteMarker = new google.maps.Marker({
          position: location,
          icon: rnSvgMarkerIcon,
          map,
          draggable: false,
          title: "RN Marker",
        });

        /* remoteMarker.addListener("dragend", (event) => {
          const newLatLng = {
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
          };

          newLocation = newLatLng;
          mapDragEvent.value = true;
          updateLocationInfo(newLatLng);
          moveToLocation(newLocation);
        }); */

        remoteMarker.addListener("click", (event, newLocation) => {
          let newLatLng = {
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
          };
          if (newLocation) newLatLng = newLocation;
          showInfoWindow(newLatLng, remoteMarker);
        });

        if (!mapClickEvent.value && !zoomChanged.value) {
          moveToLocation(location);
        }

        addressChanged.value = false;
        mapClickEvent.value = false;
        mapDragEvent.value = false;
      }
    };

    const moveToLocation = (location) => {
      const currentCenter = map.getCenter();

      // Check if the new position is different from the current center
      if (
        currentCenter.lat() !== location.lat ||
        currentCenter.lng() !== location.lng
      ) {
        // If different, pan the map to the new position
        map.panTo(new google.maps.LatLng(location.lat, location.lng));
      }
    };

    const updateLocationInfo = (latLng) => {
      getAddressFromLatLng(latLng, (address) => {
        emit("update-lat-lng", {
          coordinates: latLng,
          address,
          clicked: mapClickEvent.value,
        });
      });
    };

    const showInfoWindow = (latLng, marker) => {
      getAddressFromLatLng(latLng, (address) => {
        let newLatitude = latLng.lat.toFixed(6);
        let newLongitude = latLng.lng.toFixed(6);
        const contentString = `
        <div class="info-window">
          <h3>Location Info</h3>
          <table class="table">
            <tr>
              <td class="fw-bold">Address</td>
              <td>${address || "N/A"}</td>
            </tr>
            <tr>
              <td class="fw-bold">Latitude</td>
              <td>${newLatitude}</td>
            </tr>
            <tr>
              <td class="fw-bold">Longitude</td>
              <td>${newLongitude}</td>
            </tr>
          </table>
        </div>
      `;

        if (infoWindow) infoWindow.close();
        infoWindow.setContent(contentString);
        infoWindow.open(map, marker);
      });
    };

    const calculateCenterPoint = (nodes) => {
      const totalNodes = nodes.length;
      const sumLat = nodes.reduce((sum, node) => sum + node.latitude, 0);
      const sumLng = nodes.reduce((sum, node) => sum + node.longitude, 0);

      return {
        lat: sumLat / totalNodes,
        lng: sumLng / totalNodes,
      };
    };

    const updateMapWithBaseNodes = (baseNodeList) => {
      /* let bnMarkerIcon = {
        url: bnEncodedSVG,
        scaledSize: new google.maps.Size(40, 40),
        anchor: new google.maps.Point(20, 40),
      }; */

      clusterClicked.value = false;

      // Define MarkerClusterer options
      const clusterOptions = {
        minimumClusterSize: 2,
        maxZoom: 15,
      };

      // Clear previous MarkerClusterer and markers
      if (markerClusterer) {
        markerClusterer.clearMarkers();
      }

      baseNodeMarkers.forEach((marker) => marker.setMap(null));
      baseNodeMarkers = [];

      polygonArray.forEach((polygon) => polygon.setMap(null));
      polygonArray = [];

      /* polylineArray.forEach((polyline) => polyline.setMap(null));
      polylineArray = []; */

      if (radiusCircle) {
        radiusCircle.setMap(null);
      }

      if (radiusRectangle) {
        radiusRectangle.setMap(null);
      }

      if (!baseNodeList.showBNRadius) {
        return;
      }

      const usedCoordinates = new Set();

      if (baseNodeList.bn_list.length > 0) {
        let baseNodeRadius = parseInt(baseNodeList.radius);
        let centerPoint = calculateCenterPoint(baseNodeList.bn_list);

        if (!baseNodeList.rectangleAPICall) {
          radiusCircle = new google.maps.Circle({
            strokeColor: "#4285F4",
            strokeOpacity: 0.6,
            strokeWeight: 2,
            fillColor: "#4285F4",
            fillOpacity: 0.1,
            map,
            center: centerPoint,
            radius: baseNodeRadius,
          });
        }

        baseNodeList.bn_list.forEach((node) => {
          let lat = node.latitude;
          let lng = node.longitude;

          let coordinateKey = `${lat},${lng}`;

          // Apply offset if coordinate already exists
          while (usedCoordinates.has(coordinateKey)) {
            lat += Math.random() * 0.0001 - 0.00005; // Slight offset
            lng += Math.random() * 0.0001 - 0.00005;
            coordinateKey = `${lat},${lng}`;
          }

          usedCoordinates.add(coordinateKey);

          if (node.azimuth !== null && node.azimuth !== undefined) {
            const azimuthAngle = (node.azimuth * Math.PI) / 180; // Convert azimuth to radians
            const spreadAngle = 40 * (Math.PI / 180); // ±40° spread angle (converted to radians)
            const distance = 0.01; // Distance for the polygon edges (adjust based on zoom level)
            const steps = 50; // Number of steps to create a smooth curve
            const arcPoints = [];

            // Generate arc points along the curve (top of the arc)
            for (let i = 0; i <= steps; i++) {
              const angle =
                azimuthAngle - spreadAngle + (i * 2 * spreadAngle) / steps; // Calculate each angle in the arc

              // Calculate latitude and longitude offsets using spherical geometry
              const latOffset = distance * Math.cos(angle); // Latitude offset based on the angle
              const lngOffset = distance * Math.sin(angle); // Longitude offset based on the angle

              // Correct longitude for Earth's curvature by dividing by the cosine of latitude
              arcPoints.push({
                lat: lat + latOffset, // Latitude change
                lng: lng + lngOffset / Math.cos((lat * Math.PI) / 180), // Longitude adjustment for curvature
              });
            }

            // Construct the polygon path for the sector, including the arc and arrow
            const polygonPath = [
              { lat, lng }, // Start point (center of the sector)
              ...arcPoints, // Arc points along the curve
              { lat, lng }, // Close the polygon by going back to the center (this ensures closure)
            ];

            // Draw the sector as a filled polygon
            const azimuthPolygon = new google.maps.Polygon({
              paths: polygonPath, // Use the constructed path
              strokeColor: "#438973", // Outline color
              strokeOpacity: 1, // Outline opacity
              strokeWeight: 2, // Outline thickness
              fillColor: "#5ebc8b", // Fill color
              fillOpacity: 0.4, // Fill opacity
              map, // Add to the map
              clickable: false,
            });

            // Add the polygon to the array for later removal
            polygonArray.push(azimuthPolygon);
            //polylineArray.push(azimuthPolyline);
          }

          const baseNodeMarker = new google.maps.Marker({
            position: { lat, lng },
            icon: bnMarkerIcon,
            title: "BN Marker",
          });

          baseNodeMarker.addListener("click", () => {
            const contentString = `
              <div class="info-window">
                <h3>BN Information</h3>
                <table class="table">
                  <tr>
                    <td class="fw-bold">Operator Name</td>
                    <td>${node.operator}</td>
                  </tr>
                  <tr>
                    <td class="fw-bold">Region Name</td>
                    <td>${node.region}</td>
                  </tr>
                  <tr>
                    <td class="fw-bold">Market Name</td>
                    <td>${node.market}</td>
                  </tr>
                  <tr>
                    <td class="fw-bold">Site Name</td>
                    <td>${node.site}</td>
                  </tr>
                  <tr>
                    <td class="fw-bold">Cell Name</td>
                    <td>${node.cell}</td>
                  </tr>
                  <tr>
                    <td class="fw-bold">Sector Name</td>
                    <td>${node.sector}</td>
                  </tr>
                  <tr>
                    <td class="fw-bold">Serial Number</td>
                    <td>${node.serial}</td>
                  </tr>
                  <tr>
                    <td class="fw-bold">Latest Timestamp</td>
                    <td>${formatDate(node.timestamp)}</td>
                  </tr>
                  <tr>
                    <td class="fw-bold">Firmware Version</td>
                    <td>${node.firmware}</td>
                  </tr>
                  <tr>
                    <td class="fw-bold">Subscriber Count</td>
                    <td>${node.subscribers}</td>
                  </tr>
                  <tr>
                    <td class="fw-bold">Latitude</td>
                    <td>${node.latitude.toFixed(6)}</td>
                  </tr>
                  <tr>
                    <td class="fw-bold">Longitude</td>
                    <td>${node.longitude.toFixed(6)}</td>
                  </tr>
                  <tr>
                    <td class="fw-bold">Height (AGL)</td>
                    <td>${
                      node.height_agl
                        ? `${node.height_agl.toFixed(2)} meters`
                        : ""
                    }</td>
                  </tr>
                  <tr>
                    <td class="fw-bold">Azimuth</td>
                    <td>${
                      node.azimuth
                        ? `${node.azimuth.toFixed(2)} degrees (cw from north)`
                        : ""
                    }</td>
                  </tr>
                  <tr>
                    <td class="fw-bold">Downtilt</td>
                    <td>${
                      node.downtilt ? `${node.downtilt.toFixed(2)} degrees` : ""
                    }</td>
                  </tr>
                  <tr>
                    <td class="fw-bold">Carrier Frequencies</td>
                    <td>${formatValues("frequency", node.carrier)}</td>
                  </tr>
                  <tr>
                    <td class="fw-bold">Carrier Bandwidths</td>
                    <td>${formatValues("bandwidth", node.carrier)}</td>
                  </tr>
                  <tr>
                    <td class="fw-bold">Carrier TX Powers</td>
                    <td>${formatValues("tx_power", node.carrier)}</td>
                  </tr>
                </table>
              </div>
            `;

            infoWindow.setContent(contentString);
            infoWindow.open(map, baseNodeMarker);

            emit("update-base-node", node);
          });

          baseNodeMarkers.push(baseNodeMarker);
        });

        //map.setZoom(zoomLevel);
      }

      markerClusterer = new MarkerClusterer({
        markers: baseNodeMarkers,
        map: map,
        ...clusterOptions,
      });

      // Listen for cluster click
      markerClusterer.addListener("click", (event) => {
        clusterClicked.value = true;
      });
    };

    const formatDate = (timestamp) => {
      if (timestamp) {
        let date = new Date(timestamp);
        const formattedDate =
          date.getFullYear() +
          "-" +
          String(date.getMonth() + 1).padStart(2, "0") +
          "-" +
          String(date.getDate()).padStart(2, "0");
        return formattedDate;
      }
      return "";
    };

    // Helper function to extract and format values
    const formatValues = (key, data) => {
      if (data.length > 0) {
        let carrier = data
          .filter((item) => item[key] !== 0) // Exclude entries with zero values
          .map((item) => item[key])
          .join(", ");
        carrier += carrier ? (key == "tx_power" ? " dB" : " MHz") : "";
        return carrier;
      }
      return "";
    };

    const updateMap = (baseNode, remoteNode) => {
      // Clear existing polyline and markers
      if (route) {
        route.setMap(null); // Remove polyline
        route = null; // Reset route variable
      }
      if (baseMarker) {
        baseMarker.setMap(null); // Remove base marker
        baseMarker = null; // Reset baseMarker variable
      }
      if (remoteMarker) {
        remoteMarker.setMap(null); // Remove remote marker
        remoteMarker = null; // Reset remoteMarker variable
      }

      // Define coordinates for two points (replace with actual coordinates)
      const baseLatLng = new google.maps.LatLng(baseNode.lat, baseNode.lng);
      const remoteLatLng = new google.maps.LatLng(
        remoteNode.lat,
        remoteNode.lng
      );

      let baseNodeExist = false;
      let remoteNodeExist = false;

      if (baseNode.lat != "" && baseNode.lng != "") {
        baseMarker = new google.maps.Marker({
          position: baseLatLng,
          map,
          icon: bnMarkerIcon,
          title: "BN Marker",
        });

        baseMarker.addListener("click", (event) => {
          const newLatLng = {
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
          };
          showInfoWindow(newLatLng, baseMarker);
        });

        moveToLocation(baseNode);
        baseNodeExist = true;
      }

      if (remoteNode.lat != "" && remoteNode.lng != "") {
        remoteMarker = new google.maps.Marker({
          position: remoteLatLng,
          icon: rnMarkerIcon,
          map,
          draggable: false,
          title: "RN Marker",
        });

        remoteMarker.addListener("click", (event) => {
          const newLatLng = {
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
          };
          showInfoWindow(newLatLng, remoteMarker);
        });

        moveToLocation(remoteNode);
        remoteNodeExist = true;
      }

      if (baseNodeExist && remoteNodeExist) {
        // Define bounds to encompass both base and remote nodes
        const bounds = new google.maps.LatLngBounds();
        bounds.extend(baseLatLng);
        bounds.extend(remoteLatLng);

        // Add extra padding to the bounds (e.g., upward)
        const northEast = bounds.getNorthEast();
        const southWest = bounds.getSouthWest();
        const verticalPadding = 0.1; // Adjust this value for more/less padding
        const adjustedBounds = new google.maps.LatLngBounds(
          new google.maps.LatLng(
            southWest.lat() - verticalPadding,
            southWest.lng()
          ),
          new google.maps.LatLng(
            northEast.lat() + verticalPadding,
            northEast.lng()
          )
        );

        // Fit the map to these adjusted bounds
        map.fitBounds(adjustedBounds);

        // Calculate distance between base and remote nodes
        const distance = google.maps.geometry.spherical.computeDistanceBetween(
          baseLatLng,
          remoteLatLng
        );

        // Adjust zoom level based on distance
        let zoomLevel = 12; // Default zoom level
        if (distance < 1000) {
          zoomLevel = 14; // Adjust zoom level for closer distances
        } else if (distance > 10000) {
          zoomLevel = 12; // Adjust zoom level for closer distances
        } else if (distance > 100000) {
          zoomLevel = 10; // Adjust zoom level for closer distances
        } else if (distance > 1000000) {
          zoomLevel = 8; // Adjust zoom level for larger distances
        } else if (distance > 10000000) {
          zoomLevel = 6;
        }

        // Set the map's zoom to the calculated max zoom level if it exceeds it
        google.maps.event.addListenerOnce(map, "bounds_changed", () => {
          if (map.getZoom() > zoomLevel) {
            map.setZoom(zoomLevel);
          }
        });

        const lineSymbol = {
          path: google.maps.SymbolPath.CIRCLE,
          fillOpacity: 1,
          scale: 0.8,
        };

        // Draw a polyline between the two points
        route = new google.maps.Polyline({
          path: [baseLatLng, remoteLatLng],
          geodesic: true,
          strokeColor: "#000000",
          strokeOpacity: 0,
          icons: [
            {
              icon: lineSymbol,
              offset: "0",
              repeat: "3px",
            },
          ],
        });
        route.setMap(map);
        resetAll.value = true;
        clearAllMarkers.value = true;
        lockZoom.value = true;

        // Reposition the map center slightly upward to move the polyline toward the top
        google.maps.event.addListenerOnce(map, "idle", () => {
          const center = map.getCenter();
          const mapHeightOffset = 0.1; // Adjust this value to control how much the map shifts
          const adjustedLatLng = {
            lat: center.lat() - mapHeightOffset,
            lng: center.lng(),
          };
          moveToLocation(adjustedLatLng);
        });

        // Clear the map click event listener
        if (mapClickListener) {
          google.maps.event.removeListener(mapClickListener);
          mapClickListener = null; // Clear the reference
        }
        google.maps.event.clearListeners(map, "zoom_changed");
      }
    };

    const resizeMap = () => {
      if (mapDivRef.value) {
        mapDivRef.value.style.height = window.innerHeight - 40 + "px";
        google.maps.event.clearListeners(map, "zoom_changed");
        google.maps.event.clearListeners(map, "idle");
      }
    };

    return {
      mapDivRef,
      mapType,
      lockZoom,
      resetAll,
      resetAllValues,
      loadingBaseNodes,
      toggleLockZoom,
      handleZoomDragforBN,
      setMapType,
    };
  },
};
</script>
